import { css } from '@emotion/react';
import { Image, Typography } from '@uniquegood/realworld-studio-design';
import { ReactChild } from 'react';

import { ATagStyle, ColumnFlex, JustifyCenterFlex } from '@/styles';
import { helpLink } from '@/utils';
import { tooltipImage } from '../../assets';

const Text = () => {
  return (
    <StringTooltip>
      리액션 정의의 실행 조건을 ‘특정 텍스트를 입력함’으로 설정하고 원하는 텍스트를 입력해 주세요
    </StringTooltip>
  );
};

const DirectionSequence = () => {
  return (
    <StringTooltip>
      {`리액션 정의의 실행 조건을 ‘특정 텍스트를 입력함’으로 설정하고, 원하는 방향을 알파벳
      약자(상=U, 하=D, 좌=L, 우=R)로 입력해 주세요. ex) LLURRDU`}
    </StringTooltip>
  );
};

const Password = () => {
  return (
    <StringTooltip>
      리액션 정의의 실행 조건을 ‘특정 텍스트를 입력함’으로 설정하고 4자리 숫자를 입력해 주세요
    </StringTooltip>
  );
};

const Button = () => {
  return (
    <StringTooltip>
      <ul>
        <li>리액션 화면으로 넘어가기 전 버튼이 있는 화면이 표시돼요.</li>
        <li>액션 설정 항목을 입력하고, 리액션 실행 조건을 ‘조건 없음’으로 설정해 주세요.</li>
      </ul>
    </StringTooltip>
  );
};

const AR = () => {
  return {
    Type: (
      <StringTooltip>
        <ul>
          <li>
            카메라로 특정 이미지를 비출 경우, 그 위로 다른 이미지를 오버레이 시키는 기능이에요.
          </li>
          <li>아래의 액션 설정 항목을 채우고, 리액션 실행 조건을 ‘조건 없음’으로 설정해주세요.</li>
          <li>
            자세한 사용방법은{' '}
            <a
              href="https://rwd.to/realworldstudio-manual-AR"
              target="_blank"
              rel="noreferrer"
              css={ATagStyle}
            >
              매뉴얼
            </a>
            을 참고해주세요.
          </li>
        </ul>
      </StringTooltip>
    ),
    TargetImage: (
      <StringTooltip>
        <ul>
          <li>
            AR을 적용할 대상의 이미지로, 플레이어가 해당 이미지를 비추면 오버레이 이미지가 보여져요.
          </li>
          <li>3D 이미지보다 평면 이미지를 사용하면 인식률이 더 높아져요.</li>
        </ul>
      </StringTooltip>
    ),
    OverlayImage: (
      <StringTooltip>
        <ul>
          <li>AR을 적용할 대상 위에 나타나는 이미지예요.</li>
          <li>플레이어가 대상을 비춰 AR 인식에 성공하면 나타나요.</li>
        </ul>
      </StringTooltip>
    ),
    GuideImage: (
      <div css={[ColumnFlex, JustifyCenterFlex]}>
        <StringTooltip>
          플레이어가 비추어야 할 대상에 대한 힌트를 주고 싶을 때 사용하는 항목이에요.
        </StringTooltip>
        <Image alt="가이드 이미지" source={tooltipImage.guidImage} css={GuideImageStyle} />
      </div>
    ),
    TargetHeight: (
      <StringTooltip>
        카메라로 찍어야하는 이미지의 너비/높이를 대략적으로 적어주시면 AR의 인식률이 높아져요.
      </StringTooltip>
    ),
    TargetWidth: (
      <StringTooltip>
        카메라로 찍어야하는 이미지의 너비/높이를 대략적으로 적어주시면 AR의 인식률이 높아져요.
      </StringTooltip>
    )
  };
};

const QR = () => {
  return (
    <StringTooltip>
      <ul>
        <li>
          리액션 실행 조건을 ‘특정 텍스트를 입력함’으로 설정하고, QR코드에 저장된 값을 입력해주세요.
        </li>
        <li>
          네이버에서 생성한 QR코드는 사용할 수 없어요.{' '}
          <a
            href="https://ko.qr-code-generator.com/"
            target="_blank"
            rel="noreferrer"
            css={ATagStyle}
          >
            다른 사이트
          </a>
          에서 생성된 QR코드를 사용해주세요.
        </li>
      </ul>
    </StringTooltip>
  );
};

const FrameShot = () => {
  return (
    <StringTooltip>
      <ul>
        <li>사진 촬영 시 업로드한 프레임 이미지가 오버레이 되어요.</li>
        <li>아래의 액션 설정 항목을 채우고 리액션 실행 조건을 ‘조건 없음’으로 설정해주세요.</li>
      </ul>
    </StringTooltip>
  );
};

const Nfc = () => {
  return (
    <StringTooltip>
      <ul>
        <li>실물 NFC태그가 필요한 기능이에요.</li>
        <li>
          리액션 실행 조건을 ‘특정 텍스트를 입력함’으로 설정하고 NFC 태그에 저장한 값을
          입력해주세요.
        </li>
      </ul>
    </StringTooltip>
  );
};
const GpsRadar = () => {
  return (
    <StringTooltip>
      <ul>
        <li>입력한 목적지를 가리키는 나침반을 화면에 보여줘요. 실내에서는 작동하지 않아요.</li>
        <li>아래의 액션 설정 항목을 채우고 리액션 실행 조건을 ‘조건 없음’으로 설정해주세요.</li>
        <li>
          자세한 사용방법은{' '}
          <a
            href="https://rwd.to/realworldstudio-manual-GPS"
            target="_blank"
            rel="noreferrer"
            css={ATagStyle}
          >
            매뉴얼
          </a>
          을 참고해주세요.
        </li>
      </ul>
    </StringTooltip>
  );
};

const WebView = () => {
  return (
    <StringTooltip>
      <ul>
        <li>웹뷰 빌더로 다채로운 액션 페이지를 만들 수 있어요.</li>
        <li>웹 기술(HTML, CSS, JavaScript)을 활용하여 나만의 액션을 만들 수 있어요.</li>
        <li>
          자세한 사용방법은{' '}
          <a
            href="https://rwd.to/realworldstudio-manual-Webview"
            target="_blank"
            rel="noreferrer"
            css={ATagStyle}
          >
            매뉴얼
          </a>
          과{' '}
          <a href={helpLink.community} target="_blank" rel="noreferrer" css={ATagStyle}>
            커뮤니티
          </a>
          에서 확인해 주세요.
        </li>
      </ul>
    </StringTooltip>
  );
};

const FrameShotV2 = () => {
  return (
    <StringTooltip>
      <ul>
        <li>
          촬영한 이미지를 특정 이미지의 지정 위치에 얹는 액션으로, 현재 사용되지 않는 기능입니다.
        </li>
      </ul>
    </StringTooltip>
  );
};

const ColorFilter = () => {
  return (
    <StringTooltip>
      <ul>
        <li>셀로판지를 통해 보는 것 처럼 카메라에 색상 필터를 적용합니다.</li>
      </ul>
    </StringTooltip>
  );
};

const GpsMap = () => {
  return (
    <StringTooltip>
      <ul>
        <li>입력한 목적지를 가리키는 나침반을 화면에 보여줘요. 실내에서는 작동하지 않아요.</li>
        <li>B2G 전용으로 개발되었습니다.</li>
      </ul>
    </StringTooltip>
  );
};

const CameraOverlap = () => {
  return (
    <StringTooltip>
      <ul>
        <li>카메라 화면에서 이미지가 겹쳐 보이게 하는 기능입니다.</li>
      </ul>
    </StringTooltip>
  );
};

const ImageDetection = () => ({
  Type: (
    <StringTooltip>
      <ul>
        <li>
          카메라를 비출 경우, 액션 설정 항목 중 플레이어가 찍어야 하는 이미지와 얼마나 유사한지
          비교하는 기능이에요.
        </li>
        <li>아래의 액션 설정 항목을 채우고, 리액션 실행 조건을 ‘조건 없음’으로 설정해주세요.</li>
      </ul>
    </StringTooltip>
  ),
  TargetImage: (
    <StringTooltip>
      유사도 비교에 기준이 될 이미지로, 플레이어가 카메라를 비출 때 보이는 장면과 얼마나 유사한지
      비교합니다.
    </StringTooltip>
  ),
  GuideImage: (
    <div css={[ColumnFlex, JustifyCenterFlex]}>
      <StringTooltip>
        플레이어가 비추어야 할 대상에 대한 힌트를 주고 싶을 때 사용하는 항목이에요.
      </StringTooltip>
      {/* TODO: 정식 출시 이후 이미지 변경하기 */}
      <Image alt="가이드 이미지" source={tooltipImage.guidImage} css={GuideImageStyle} />
    </div>
  ),
  TargetWidth: (
    <StringTooltip>
      카메라로 찍어야하는 이미지의 너비/높이를 대략적으로 적어주시면 이미지 인식률이 높아져요.
    </StringTooltip>
  ),
  TargetHeight: (
    <StringTooltip>
      카메라로 찍어야하는 이미지의 너비/높이를 대략적으로 적어주시면 이미지 인식률이 높아져요.
    </StringTooltip>
  ),
  MatchRate: (
    <StringTooltip>
      카메라로 보여주는 장면이 타깃 이미지와 얼마나 유사하면 통과할지 정해요.
    </StringTooltip>
  )
});

const ImageDetectionScratch = () => ({
  Type: (
    <StringTooltip>
      <ul>
        <li>카메라로 특정 이미지를 비출 경우, 그 위로 다른 이미지를 오버레이 시키는 기능이에요.</li>
        <li>아래의 액션 설정 항목을 채우고, 리액션 실행 조건을 ‘조건 없음’으로 설정해주세요.</li>
      </ul>
    </StringTooltip>
  ),
  TargetImage: (
    <StringTooltip>
      유사도 비교에 기준이 될 이미지로, 플레이어가 카메라를 비출 때 보이는 장면과 얼마나 유사한지
      비교합니다.
    </StringTooltip>
  ),
  GuideImage: (
    <div css={[ColumnFlex, JustifyCenterFlex]}>
      <StringTooltip>
        플레이어가 비추어야 할 대상에 대한 힌트를 주고 싶을 때 사용하는 항목이에요.
      </StringTooltip>
      {/* TODO: 정식 출시 이후 이미지 변경하기 */}
      <Image alt="가이드 이미지" source={tooltipImage.guidImage} css={GuideImageStyle} />
    </div>
  ),
  TargetWidth: (
    <StringTooltip>
      카메라로 찍어야하는 이미지의 너비/높이를 대략적으로 적어주시면 이미지 인식률이 높아져요.
    </StringTooltip>
  ),
  TargetHeight: (
    <StringTooltip>
      카메라로 찍어야하는 이미지의 너비/높이를 대략적으로 적어주시면 이미지 인식률이 높아져요.
    </StringTooltip>
  ),
  MatchRate: (
    <StringTooltip>
      카메라로 보여주는 장면이 타깃 이미지와 얼마나 유사하면 통과할지 정해요.
    </StringTooltip>
  ),
  Foreground: (
    <StringTooltip>
      <ul>
        <li>인식 성공 시 화면에 나타나는 이미지예요.</li>
        <li>플레이어가 카메라를 비춰 유사도 비교에 성공하면 나타나요.</li>
      </ul>
    </StringTooltip>
  ),
  Background: (
    <StringTooltip>
      <ul>
        <li>인식 성공 시 화면에 나타나는 이미지를 스크래치하면 나오는 이미지에요.</li>
        <li>
          플레이어가 카메라를 비춰 유사도 비교에 성공하고 나온 이미지를 스크래치하면 나타나요.
        </li>
      </ul>
    </StringTooltip>
  ),
  ButtonLabel: (
    <StringTooltip>
      인식 성공 시 화면에 나타나는 이미지를 스크래치를 완료하면 나오는 버튼의 문구에요.
    </StringTooltip>
  )
});

export const ReactionTooltip = {
  Text,
  DirectionSequence,
  Password,
  Button,
  AR,
  QR,
  FrameShot,
  Nfc,
  GpsRadar,
  WebView,
  FrameShotV2,
  ColorFilter,
  GpsMap,
  CameraOverlap,
  ImageDetection,
  ImageDetectionScratch
};

const TooltipWidth = css`
  max-width: 320px;
`;

const TooltipResetCss = css`
  ul {
    list-style: initial;
    padding-left: 20px;
  }
`;

const GuideImageStyle = css`
  height: 310px;
  width: 279px;
  object-fit: cover;
`;

const StringTooltip = ({ children }: { children: ReactChild }) => {
  return (
    <Typography as="span" type="body" cssStyle={[TooltipWidth, TooltipResetCss]}>
      {children}
    </Typography>
  );
};
