import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import smoothscroll from 'smoothscroll-polyfill';
import { HelmetProvider } from 'react-helmet-async';

import { APP_ENV, VITE_SENTRY_RELEASE } from './env';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { HackleProvider } from '@/providers/Hackle';

smoothscroll.polyfill();

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://63afae0aebe64289bef85c7385239a11@o1134032.ingest.sentry.io/6181106',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    environment: APP_ENV,
    release: VITE_SENTRY_RELEASE,
    maxBreadcrumbs: 20
  });
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <HackleProvider>
        <App />
      </HackleProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
