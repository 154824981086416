import { ReactionTooltip } from '../components/action/Tooltips';
import { IActionTypeDefinition } from '../types';

export const actionTypeDefinitions: IActionTypeDefinition[] = [
  {
    type: 'RealWorld.ActionTypes.Dummy',
    label: '액션 없음',
    icon: 'times_regular',
    availableActionAdapters: ['quest', 'item', 'chatbot'],
    parameterDefinitions: []
  },
  {
    type: 'RealWorld.ActionTypes.Text',
    label: '텍스트 입력',
    icon: 'pen_solid',
    availableActionAdapters: ['quest', 'item', 'chatbot'],
    parameterDefinitions: [],
    tooltip: <ReactionTooltip.Text />
  },
  {
    type: 'RealWorld.ActionTypes.DirectionSequence',
    label: '방향 입력',
    icon: 'arrows_alt_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [],
    tooltip: <ReactionTooltip.DirectionSequence />
  },
  {
    type: 'RealWorld.ActionTypes.Password',
    label: '4자리 비밀번호 입력',
    icon: 'unlock_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [],
    tooltip: <ReactionTooltip.Password />
  },
  {
    type: 'RealWorld.ActionTypes.Button',
    label: '버튼 클릭',
    icon: 'arrow_circle_right_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'buttonLabel',
        type: 'text',
        description: '버튼 문구',
        style: 'nowrap'
      }
    ],
    tooltip: <ReactionTooltip.Button />
  },
  {
    type: 'RealWorld.ActionTypes.AR',
    label: '[카메라] AR 이미지 인식',
    icon: 'expand_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'targetImage',
        type: 'image',
        description: '플레이어가 찍어야 하는 타깃 이미지(jpg 파일)',
        tooltip: ReactionTooltip.AR().TargetImage,
        style: 'nowrap',
        accepts: ['image/jpeg'],
        maxFileSize: 3_145_728
      },
      {
        key: 'overlayImage',
        type: 'image',
        description: '인식 성공 시 보여질 오버레이 이미지',
        tooltip: ReactionTooltip.AR().OverlayImage,
        style: 'nowrap',
        accepts: ['image/png', 'image/jpeg', 'image/gif'],
        maxFileSize: 3_145_728
      },
      {
        key: 'guideImage',
        type: 'image',
        description: '가이드 이미지 (선택)',
        tooltip: ReactionTooltip.AR().GuideImage,
        maxFileSize: 3_145_728
      },
      {
        key: 'targetWidth',
        type: 'text',
        description: '찍어야 하는 이미지 너비 (m 단위)',
        placeholder: '예) 0.1',
        tooltip: ReactionTooltip.AR().TargetWidth,
        style: 'nowrap'
      },
      {
        key: 'targetHeight',
        type: 'text',
        description: '찍어야 하는 이미지 높이 (m 단위)',
        placeholder: '예) 0.1',
        tooltip: ReactionTooltip.AR().TargetHeight,
        style: 'nowrap'
      },
      {
        key: 'buttonLabel',
        type: 'text',
        description: '촬영 성공 시 누를 버튼 문구',
        placeholder: '예) 다음 미션',
        style: 'nowrap'
      }
    ],
    tooltip: ReactionTooltip.AR().Type
  },
  {
    type: 'RealWorld.ActionTypes.QR',
    label: '[카메라] QR코드 인식',
    icon: 'qrcode_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [],
    tooltip: <ReactionTooltip.QR />
  },
  {
    type: 'RealWorld.ActionTypes.FrameShot',
    label: '[카메라] 프레임 사진 촬영',
    icon: 'camera_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'frameImage',
        type: 'image',
        description: '프레임 이미지(1080x1080 권장, png 파일)',
        style: 'nowrap',
        useFor: 'frameshot-frame'
      },
      {
        key: 'cameraDirection',
        type: 'segmented',
        description: '전/후면 카메라 선택',
        source: [
          {
            label: '전면 카메라',
            value: 'front'
          },
          {
            label: '후면 카메라',
            value: 'back'
          }
        ],
        style: 'nowrap'
      }
    ],
    tooltip: <ReactionTooltip.FrameShot />
  },
  {
    type: 'RealWorld.ActionTypes.Nfc',
    label: '[NFC] NFC 태그 인식',
    icon: 'tags_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [],
    tooltip: <ReactionTooltip.Nfc />
  },
  {
    type: 'RealWorld.ActionTypes.ColorFilter',
    label: '[카메라] 컬러 필터 렌즈',
    icon: 'camera_solid',
    availableActionAdapters: ['quest'],
    parameterDefinitions: [
      {
        key: 'color',
        type: 'text',
        description: '필터 색상',
        placeholder: '빨간색 예) #ff0000'
      },
      {
        key: 'cameraDirection',
        type: 'segmented',
        description: '전/후면 카메라 선택',
        source: [
          {
            label: '전면 카메라',
            value: 'front'
          },
          {
            label: '후면 카메라',
            value: 'back'
          }
        ],
        style: 'nowrap'
      },
      {
        key: 'buttonLabel',
        type: 'text',
        description: '닫기 버튼 레이블',
        placeholder: '예) 특수 렌즈 닫기'
      }
    ],
    tooltip: <ReactionTooltip.ColorFilter />
  },
  {
    type: 'RealWorld.ActionTypes.GpsRadar',
    label: '[GPS] 특정 장소로 이동',
    icon: 'compass_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        type: 'targetLocation'
      },
      {
        key: 'buttonLabel',
        type: 'text',
        description: '허용 반경 진입 시 보여질 버튼 레이블',
        placeholder: '예) 목적지에 도착했어요'
      },
      {
        type: 'theme',
        description: '테마 선택',
        source: [
          {
            label: '기본 테마',
            value: 'default'
          },
          {
            label: '커스텀 테마',
            value: 'custom'
          }
        ],
        style: 'nowrap'
      }
    ],
    tooltip: <ReactionTooltip.GpsRadar />
  },
  {
    type: 'RealWorld.ActionTypes.WebView',
    label: '웹뷰 페이지 활용',
    icon: 'code_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'content',
        type: 'html'
      }
    ],
    tooltip: <ReactionTooltip.WebView />
  },
  {
    type: 'RealWorld.ActionTypes.FrameShotV2',
    label: '[미사용] 다기능 프레임 사진 촬영',
    icon: 'camera_solid',
    availableActionAdapters: ['quest', 'item'],
    disabled: true,
    parameterDefinitions: [
      {
        key: 'wholeImage',
        type: 'text',
        description: '전체 이미지의 URL',
        style: 'nowrap'
      },
      {
        key: 'frameImage',
        type: 'text',
        description: '프레임 이미지의 URL',
        style: 'nowrap'
      },
      {
        key: 'cameraDirection',
        type: 'list',
        description: '전/후면 카메라 선택',
        source: [
          {
            label: '전면 카메라',
            value: 'front'
          },
          {
            label: '후면 카메라',
            value: 'back'
          }
        ],
        style: 'nowrap'
      },
      {
        key: 'frameCoordinate',
        type: 'text',
        description: '프레임이 위치할 좌표 (`x1,y1,x2,y2` 형식)',
        style: 'nowrap'
      },
      {
        key: 'colorTransform',
        type: 'text',
        description: '색상 변환 3x3 매트릭스 ((1,1) (1,2) ... (3,3) 순)',
        style: 'nowrap'
      },
      {
        key: 'shareMessage',
        type: 'text',
        description: 'SNS공유 기본 메시지',
        style: 'nowrap'
      }
    ],
    tooltip: <ReactionTooltip.FrameShotV2 />
  },
  {
    type: 'RealWorld.ActionTypes.GpsMap',
    label: '[GPS] 특정 여러 장소로 이동',
    icon: 'compass_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'allClearInput',
        type: 'text',
        description: '전체 성공 시 입력될 텍스트',
        placeholder: '전체 클리어'
      },
      {
        type: 'targetLocationMap'
      }
    ],
    tooltip: <ReactionTooltip.GpsMap />,
    disabled: true
  },
  {
    type: 'RealWorld.ActionTypes.CameraOverlap',
    label: '[카메라] 오버랩',
    icon: 'camera_solid',
    availableActionAdapters: ['quest', 'item'],
    parameterDefinitions: [
      {
        key: 'imageUrl',
        type: 'image',
        description: '화면에 보여질 이미지(png 파일)',
        style: 'nowrap',
        useFor: 'overlap'
      },

      {
        key: 'buttonLabel',
        type: 'text',
        description: '버튼에 나타날 텍스트',
        style: 'nowrap'
      }
    ],
    tooltip: <ReactionTooltip.CameraOverlap />
  },
  {
    type: 'RealWorld.ActionTypes.ImageDetection',
    label: '[카메라] 이미지 비교',
    icon: 'camera_solid',
    availableActionAdapters: ['quest', 'item'],
    disabled: true,
    parameterDefinitions: [
      {
        key: 'targetImage',
        type: 'image',
        description: '플레이어가 찍어야 하는 이미지(jpg 파일)',
        tooltip: ReactionTooltip.ImageDetection().TargetImage,
        style: 'nowrap',
        accepts: ['image/jpeg'],
        maxFileSize: 3_145_728
      },
      {
        key: 'guideImage',
        type: 'image',
        description: '가이드 이미지 (선택)',
        tooltip: ReactionTooltip.ImageDetection().GuideImage,
        maxFileSize: 3_145_728
      },
      {
        key: 'targetWidth',
        type: 'text',
        description: '찍어야 하는 이미지 너비 (m 단위)',
        placeholder: '예) 0.1',
        tooltip: ReactionTooltip.ImageDetection().TargetWidth,
        style: 'nowrap'
      },
      {
        key: 'targetHeight',
        type: 'text',
        description: '찍어야 하는 이미지 높이 (m 단위)',
        placeholder: '예) 0.1',
        tooltip: ReactionTooltip.ImageDetection().TargetHeight,
        style: 'nowrap'
      }
    ],
    tooltip: ReactionTooltip.ImageDetection().Type
  },
  {
    type: 'RealWorld.ActionTypes.ImageDetection.Scratch',
    label: '[카메라] 스크래치 이미지 비교',
    icon: 'camera_solid',
    availableActionAdapters: ['quest', 'item'],
    disabled: true,
    parameterDefinitions: [
      {
        key: 'targetImage',
        type: 'image',
        description: '플레이어가 찍어야 하는 이미지(jpg 파일)',
        tooltip: ReactionTooltip.ImageDetectionScratch().TargetImage,
        style: 'nowrap',
        accepts: ['image/jpeg'],
        maxFileSize: 3_145_728
      },
      {
        key: 'guideImage',
        type: 'image',
        description: '가이드 이미지 (선택)',
        tooltip: ReactionTooltip.ImageDetectionScratch().GuideImage,
        maxFileSize: 3_145_728
      },
      {
        key: 'scratchForeground',
        type: 'image',
        description: '인식 성공 시 보여질 이미지',
        tooltip: ReactionTooltip.ImageDetectionScratch().Foreground,
        maxFileSize: 3_145_728
      },
      {
        key: 'scratchBackground',
        type: 'image',
        description: '인식 성공 시 보여질 이미지를 스크래치 하면 나오는 이미지',
        tooltip: ReactionTooltip.ImageDetectionScratch().Background,
        maxFileSize: 3_145_728
      },
      {
        key: 'targetWidth',
        type: 'text',
        description: '찍어야 하는 이미지 너비 (m 단위)',
        placeholder: '예) 0.1',
        tooltip: ReactionTooltip.ImageDetectionScratch().TargetWidth,
        style: 'nowrap'
      },
      {
        key: 'targetHeight',
        type: 'text',
        description: '찍어야 하는 이미지 높이 (m 단위)',
        placeholder: '예) 0.1',
        tooltip: ReactionTooltip.ImageDetectionScratch().TargetHeight,
        style: 'nowrap'
      },
      {
        key: 'buttonLabel',
        type: 'text',
        description: '스크래치 완료 이후 버튼 문구',
        placeholder: '예) 미션 성공',
        tooltip: ReactionTooltip.ImageDetectionScratch().ButtonLabel
      }
    ],
    tooltip: ReactionTooltip.ImageDetection().Type
  }
];
